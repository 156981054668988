.flexbin {
  flex-wrap: wrap;
  margin: -2.5px;
  display: flex;
  overflow: hidden;
}

.flexbin:after {
  content: "";
  flex-grow: 1000000000;
  min-width: 300px;
  height: 0;
}

.flexbin > * {
  flex-grow: 1;
  height: 300px;
  margin: 2.5px;
  display: block;
  position: relative;
}

.flexbin > * > img {
  object-fit: cover;
  vertical-align: bottom;
  min-width: 100%;
  max-width: 100%;
  height: 300px;
}

.flexbin.flexbin-margin {
  margin: 2.5px;
}

@media (width <= 980px) {
  .flexbin {
    flex-wrap: wrap;
    margin: -2.5px;
    display: flex;
    overflow: hidden;
  }

  .flexbin:after {
    content: "";
    flex-grow: 1000000000;
    min-width: 150px;
    height: 0;
  }

  .flexbin > * {
    flex-grow: 1;
    height: 150px;
    margin: 2.5px;
    display: block;
    position: relative;
  }

  .flexbin > * > img {
    object-fit: cover;
    vertical-align: bottom;
    min-width: 100%;
    max-width: 100%;
    height: 150px;
  }

  .flexbin.flexbin-margin {
    margin: 2.5px;
  }
}

@media (width <= 400px) {
  .flexbin {
    flex-wrap: wrap;
    margin: -2.5px;
    display: flex;
    overflow: hidden;
  }

  .flexbin:after {
    content: "";
    flex-grow: 1000000000;
    min-width: 100px;
    height: 0;
  }

  .flexbin > * {
    flex-grow: 1;
    height: 100px;
    margin: 2.5px;
    display: block;
    position: relative;
  }

  .flexbin > * > img {
    object-fit: cover;
    vertical-align: bottom;
    min-width: 100%;
    max-width: 100%;
    height: 100px;
  }

  .flexbin.flexbin-margin {
    margin: 2.5px;
  }
}
/*# sourceMappingURL=index.7162552e.css.map */
